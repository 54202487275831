import IconPage from "../componentes/IconPage"
import json from "../panelistas.json"
import panelistas from '../img/panelistas.svg'
import IconosVolver from "../componentes/IconosVolver"
import { Layout } from "../layout";
import { Link, useNavigate } from "react-router-dom"


export default function DatosPanelistas() {

  const navigate = useNavigate()
   
  const handleclick = () => { 
    navigate(`/panelista`)
};


  return (
<>

<Layout textFooter={'federación argentina del trabajador de las universidades nacionales'}>
    <IconosVolver to={'/'}/>  
    <IconPage image={panelistas} indice={'panelistas'}/>
      
    <div className="container mt-5" align="center">


      <div className="row">

        <div className="col-md-12">

          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col"></th>
                <th scope="col">Nombre</th>
              </tr>
            </thead>

            <tbody style={{textAlign:'center'}}>

              {json.map(item => (
                <tr key={item.id} style={{
                  borderTop: '1px solid #dbdbdb',
                  borderBottom: '1px solid #dbdbdb',
                }}>
                  <td><img src={`${process.env.PUBLIC_URL}/imagenes/${item.img}`} alt={item.nombre} className="img-fluid img_panelistas" /></td>
                  <Link to={`/panelista/${item.nombre}`}><td
                  style={{
                    border: '2px solid #dbdbdb',
                    textDecoration: 'underline',
                    color: '#485fc7',
                    ackground:'none',
                    border: 'none',
                    padding:'0',
                    fontFamily: 'Poppins, sans-serif',
                    cursor:'pointer',
                    lineHeight: '6'
                  }}
                  >{item.nombre}</td></Link>
                </tr>
              ))}

            </tbody>

          </table>

        </div>

      </div>

    </div>
    
    </Layout>
    </>
  )
}