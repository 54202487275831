import logoFatun from '../img/logofatun.svg'
// import fondo from '../img/fondo.png'
import Title from './Title'
import '../App.css'
import { Link } from 'react-router-dom'
import IconosVolver from './IconosVolver'


const Encabezado = ({backgroundImage}) => { 
    return (
        <>
            <div className='is-flex is-relative' 
                style={{
                    backgroundImage: backgroundImage,
                    backgroundPosition: `center`,
                    height: '40vh',
                    }} 
                >
                <a href='https://www.fatun.org.ar/'>
                <img style= {{cursor: 'pointer' }} src = {logoFatun} className= 'is-block m-auto img-responsive' alt="" /></a>
            </div>
            <Title />
        </>
    )
}

export default Encabezado