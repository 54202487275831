import '../App.css'

const IconPage = ({image, indice}) => { 
   
    return( 
         <div className="is-flex is-justify-content-center is-align-items-center mb-5 margin is-capitalized">
            <img src={image} style={{maxWidth:'3rem'}} />
            <p className="pl-2 poppins-bold">{indice}</p>
         </div>
    )
}

export default IconPage;