import Encabezado from "../componentes/Encabezado";
import Title from "../componentes/Title";
import IconPage from "../componentes/IconPage";
import info from '../img/info.svg'
import imgInfo from '../img/imgInfo.png'
import Footer from "../componentes/Footer";
import IconosVolver from "../componentes/IconosVolver";
import '../App.css'

const Info = ()=>{
 

  return(
    <>
        <IconosVolver to={'/'}/>
        <Encabezado />
        <Title />
        <IconPage image={info} indice={'información'}/>
        <img src={imgInfo} className='is-block m-auto imgInformacion'/>
        <div className="mt-6 mb-6 parrafo" style={{width:'80%', margin: 'auto', lineHeight:'1.5rem'}}>
            <p className="mb-4 has-text-justified">El próximo martes 6 y miércoles 7 de diciembre se realizará el 2º Encuentro Nacional de Jóvenes Referentes NODOCENTES de la FATUN, en el Auditorio Cro. Néstor Carlos Kirchner, del edificio central de la Federación, ubicado en Av. Belgrano 3768, Ciudad de Buenos Aires. El mismo está destinado a referentes designadas/os por cada Sindicato de base adherido a la FATUN y contará con la exposición de destacadas/os Compañeras y Compañeros de diferentes formaciones sindicales, organizaciones sociales e integrantes de la Mesa Ejecutiva de la FATUN.</p>
            
            <p className="mb-4 has-text-justified">Este encuentro es organizado por la Secretaría de la Juventud, liderado por el Cro. Facundo Romero, el cual estará cargado de capacitaciones y decisiones en torno a las acciones que la Federación implementará durante el año 2023, y en el que se espera una gran convocatoria de Jóvenes de diferentes regiones de Argentina.
            Su acto de apertura contará con la presencia del Secretario General de la FATUN, Cro. Walter Merkis, junto al Cro. Carlos Bianco, Jefe de Asesores del Gobernador de la Prov. de Buenos Aires; el Cro. Lorenzo Pepe, Diputado Nacional Mandato Cumplido; y el Cro. Facundo Romero. </p>
          
            <ul> <p className='poppins-bold'>El encuentro tiene los siguientes objetivos y metas:</p>
                <li className='ml-4 mt-2'>• Analizar los resultados obtenidos del Encuentro Nacional de Referentes de la Juventud Sindical NODOCENTE de FATUN y de los seis Encuentros Regionales realizados durante la pandemia. </li>
               
                <li className='ml-4 mt-2'>• Relevamiento de situación de Juventudes en cada Sindicato de Base (cantidad de trabajadorxs por género, rango etareo, cantidad de años de servicios, entre otros).</li>
               
                <ol className='ml-4 mt-2'>• Fomentar las siguientes líneas de acción:
                  <li className='ml-6 mt-2'>Área de Formación Sindical (formación de cuadros).</li>
                  <li className='ml-6 mt-2'>Área de Acción Social (jornadas solidarias, ollas populares, comedores).</li>
                  <li className='ml-6 mt-2'>Área de géneros, diversidad y derechos humanos.</li>
                  <li className='ml-6 mt-2'>Área de Pensamiento Político Sindical.</li>
                  <li className='ml-6 mt-2'>Área de Comunicación y Prensa.</li>
                  <li className='ml-6 mt-2'>Áreas de Salud y Asistenciales.</li>
                </ol>
               
                <li className='ml-4 mt-2'>• Fomentar la participación en la JSP CGT en cada Región del País.</li>
                
                <li className='ml-4 mt-2'>• Realizar un balance de las Jornadas Deportivas, Recreativas y de Formación Malvinas Argentinas 2022.</li>
              </ul>
          </div>
          
        <Footer texto={'federación argentina del trabajador de las universidades nacionales'}/>
        

    </>
  )
}

export default Info;