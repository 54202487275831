import { Layout } from "../layout";
import Encabezado from "../componentes/Encabezado"
import fondo from '../img/fondo.png'
import Title from "../componentes/Title";
import IconPage from "../componentes/IconPage";
import IconosVolver from "../componentes/IconosVolver";
import panelistas from '../img/panelistas.svg'
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import json from "../panelistas.json"
import '../App.css'


const Panelista = () => {
  
  const {nombre} = useParams()

  
  let panelista = json.find((panelista)=> panelista.nombre == nombre )


    const style= { 
      img: {
        borderRadius: '50%',
        margin: 'auto',
        widht: '100%'
      }
    }


   return (
      <>
        <Layout backgroundHeader={fondo} textFooter={'federación argentina del trabajador de las universidades nacionales'}>
          <Title color={'white'} />
          <IconosVolver to={'/programa'}/>
          <IconPage image={panelistas} indice={'panelista'}/>
          <div className="containerPanelista">
            <div style={{width:'100%', textAlign:'center', boxShadow: '0 2px 2px -2px rgb(82, 81, 81)', paddingBottom:'3rem'}}>
              <img src={`${process.env.PUBLIC_URL}/imagenes/${panelista.img}`} alt={panelista.nombre} style={style.img}/>  
            </div>
            <div className="p-5">
              <p className="poppins-bold">{panelista.nombre}</p>
              <p>{panelista.cargo}</p>
            </div>
          </div>
        </Layout>
      </>
      )
        }


        
export default Panelista;
        
        
        
        
        
        
        
