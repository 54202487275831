import IconPage from "../componentes/IconPage";
import ItinerarioContainer from "../componentes/ItinerarioContainer";
import programa from '../img/programa.svg'
import { Layout } from "../layout";
import IconosVolver from "../componentes/IconosVolver";
import { useState, useEffect } from "react";



const Programa = ({image, indice, height}) => { 
     
    return (    
        <>
        <Layout textFooter={'federación argentina del trabajador de las universidades nacionales'}>
          <IconPage image={programa} indice={'programa'}/>
            <ItinerarioContainer />
            <IconosVolver to={'/'}/>
        </Layout>

        </>     
    )
}


export default Programa;