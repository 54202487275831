import IconosHome from "../componentes/IconosHome";
import Title from "../componentes/Title";
import fondo from '../img/fondo.png'
import { Layout } from "../layout";
import { useEffect, useState } from "react";




const Home = () => { 

     return (
      <Layout backgroundHeader={fondo} textFooter={'federación argentina del trabajador de las universidades nacionales'}>
        <Title color={'white'} />
        <IconosHome />
      </Layout>
      )
    }
    
    export default Home;














