// BULMA
import 'bulma/css/bulma.min.css'
// PAGES
import Home from "./pages/Home";
import Programa from "./pages/Programa";
import Info from "./pages/Info";
import ListaPanelistas from "./pages/ListaPanelistas";
import Panelista from "./pages/Panelista";
import Ubicacion from "./pages/Ubicacion";

// RECT-ROUTER
import {BrowserRouter, Routes,Route} from 'react-router-dom';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/programa' element={<Programa />} />        
        <Route path='/informacion' element={<Info />} />        
        <Route path='/panelista/:nombre' element={<Panelista />} />        
        <Route path='/panelistas' element={<ListaPanelistas />} />        
        <Route path='/ubicacion' element={<Ubicacion />} />        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
