import Title from "../componentes/Title";
import IconPage from "../componentes/IconPage";
import ItinerarioContainer from "../componentes/ItinerarioContainer";
import ubicacion from '../img/ubicacion.svg'
import map from '../img/map.svg'
import '../App.css'
import { Layout } from "../layout";
import IconosVolver from "../componentes/IconosVolver";

const Ubicacion = ({image, indice, height}) => { 
     
    const style = {
        button: { 
            color: '#fff',
            padding: '1rem',
            background: '#3EC3FF',
            width: '100%',
            border: 'none',
            fontSize: '1.5rem'
        }
    }
    return (    
        <>
        
        <Layout textFooter={'federación argentina del trabajador de las universidades nacionales'}>
            <IconosVolver to={'/'}/>
            <IconPage image={ubicacion} indice={'ubicación'}/>
            

            <div className="mapIframe">
                <iframe  className='imgIframe' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.5279528049577!2d-58.4194313!3d-34.616096299999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcca5859efa4c1%3A0x6c91af1dcd286194!2sAv.%20Belgrano%203768%2C%20C1210%20CABA!5e0!3m2!1ses-419!2sar!4v1670252074094!5m2!1ses-419!2sar" frameborder="0" style={{border:0,}} allowfullscreen="" aria-hidden="false" tabindex="0">
                </iframe>
            </div>

            <div style={{margin:'auto', textAlign:'center'}} className='mt-2 buttonUbicacion'>
            <a href='https://maps.app.goo.gl/qc4sPdhnpFk3geXP8?g_st=iwb'>
                <button style={style.button} className='poppins-bold is-uppercase mt-4 mb-4 is-size-4-desktop is-size-6-mobile'>¿Cómo llegar?</button>
            </a>
            </div>
        </Layout>
        
        </>
    )
}


export default Ubicacion;