import '../App.css'

const Titulo = ({color}) => { 

    const style = { 
        background: { 
            backgroundColor: '#28AAE1',
            width: '100%',
        },
        position: { 
            position:'absolute',
            top: '31%',
            left: '0',
            right: '0',
        },
    }


    return (
        <div className='columns is-uppercase' style={style.position}>
            <div className='column is-three-fifths is-offset-one-fifth has-text-centered'>
                <div style={{color: color}}>
                    <p className='poppins-italic is-size-5-desktop is-size-6-mobile'>2° encuentro nacional de</p>
                    <p className='poppins-bold is-size-4-desktop is-size-5-mobile'>referentes de la juventud</p> 
                    <p className='poppins-bold is-inline is-size-4-desktop is-size-5-mobile pl-3 pr-3' style={style.background}>nodocentes de la fatun</p>
                </div>
                <p className='poppins-italic is-block size pt-2'>6 y 7 de dicimebre de 2022 - Ciudad de Buenos Aires</p>
            </div>
        </div>
    )
}

export default Titulo;