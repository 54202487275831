import HoraCharla from './HoraCharla'
import DetalleCharla from './DetalleCharla'
import Disertante from './Disertante'
import clock from '../img/clock.svg'
import '../App.css'
import { Link } from 'react-router-dom'


const ItinerarioContainer = () => { 
    return (
    <div className='mb-6'>    
        <div className='mb-4 mt-6'>
            <div className="mt-4 poppins-bold gridPrograma">
            <img src={clock} style={{maxWidth: '1.8rem', margin:'auto'}}/>
                <p className='color is-size-4-desktop is-size-6-mobile'>Martes 6 de diciembre</p>
             </div>
        </div>

        <div className='gridPrograma'>
            <HoraCharla hora={'09:00'}/>
            <div>
                <DetalleCharla tema={'Acreditaciones'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'10:00'}/>
            <div>
                <DetalleCharla tema={'Panel de apertura'} />
                <Disertante disertante={'Walter Merkis'} cargo={'Secretario General de la FATUN'}/>
                <Disertante disertante={'Carlos Bianco'} cargo={'Jefe de Asesores del Gobernador de la Prov. de Buenos Aires'}/>
                <Disertante disertante={'Lorenzo Pepe'} cargo={'Diputado Nacional Mandato Cumplido'}/>
                <Disertante disertante={'Facundo Romero'} cargo={'Secretario de Juventud de FATUN'}/>
            </div>
        </div>

    
        <div className='gridPrograma' >
            <HoraCharla hora={'11:30'}/>
            <div>
                <DetalleCharla tema={'Panel Político Sindical y Organización Gremial. ¿Cómo se organizan sindicalmente en las bases nuestros Sindicatos?. ¿Cómo se gestó la FATUN?'} />
                <Disertante disertante={'Walter Merkis'} cargo={'Secretario General de la FATUN'}/>
                <Disertante disertante={'Mónica Risotto'} cargo={'Directora Nacional de Asociaciones Sindicales Ministerio de Trabajo, Empleo y Seguridad Social de Nación'}/>
                <Disertante disertante={'Aritz Recalde'} cargo={'Decano de Humanidades y Arte de la UNLA'}/>
                <Disertante disertante={'Rubén Nuñez'} cargo={'Secretario Gremial de la FATUN'}/>
                <p>Modera:</p>
                <Disertante disertante={'Vanina Rosales'} cargo={'Secretaria Gremial de APUNSL'}/>
            </div>
        </div>

        <div className='gridPrograma' >
            <HoraCharla hora={'13:00'}/>
            <div>
                <DetalleCharla tema={'Almuerzo'} /> 
            </div>
        </div>

        <div className='gridPrograma' >
            <HoraCharla hora={'15:00'}/>
            <div>
                <DetalleCharla tema={'Panel Comunicación y Prensa. ¿Cómo construir comunicación orgánica?'} />
                <Disertante disertante={'Fernando Cortés'} cargo={'Secretario de Prensa de FATUN'}/>
                <Disertante disertante={'Josefina Lorenzo'} cargo={'Miembros de la Secretaría de Prensa de FATUN'}/>
                <Disertante disertante={'Lautaro Carnaval'} cargo={'Miembros de la Secretaría de Prensa de FATUN'}/>
                <Disertante disertante={'Juan Pablo Regalado'} cargo={'Portal Universidades Hoy'}/>
                <p>Modera:</p>
                <Disertante disertante={'Alejandra Paice'} cargo={'Secretaria Gremial de ATUNPA'}/>
            </div>
        </div>
        
        <div className='gridPrograma' >
            <HoraCharla hora={'17:00'}/>
            <div>
                <DetalleCharla tema={'Refrigerio'} /> 
            </div>
        </div>

        <div className='gridPrograma' >
            <HoraCharla hora={'18:00'}/>
            <div>
                <DetalleCharla tema={'Panel Perspectivas de Género, Diversidades y DDHH. ¿Cómo construimos agendas juveniles con perspectivas de género?. ¿Cómo avanzar en la formación de cuadros que incluya a las  diversidades?. ¿Cuáles son los desafíos de las políticas en materia  de Derechos Humanos en la actualidad?'} />
                <Disertante disertante={'Alicia Luna'} cargo={'Secretaria de Igualdad de Género de la FATUN'}/>
                <Disertante disertante={'Vanesa Siley'} cargo={'Secretaria General de SITRAJU'}/>
                <Disertante disertante={'Matías Facundo Moreno'} cargo={'Subsecretario de DDHH del Ministerio de Justicia y Derechos Humanos de la Provincia de Buenos Aires'}/>
                <Disertante disertante={'Claudia Lazzaro'} cargo={'Directora de Políticas de Equidad, Formación Laboral y Cuidados del Ministerio de las Mujeres, Políticas de Género y Diversidad Sexual de la Pcia. de Buenos Aires'}/>
                <p>Modera:</p>
                <Disertante disertante={'Ana Laura Ruggiero'} cargo={'Secretaria General de ANDUNA'}/>
            </div>
        </div>

        <div className='gridPrograma' >
            <HoraCharla hora={'20:00'}/>
            <div>
                <DetalleCharla tema={'Visita al Parque Temático Perón Volvió en Palermo'} />
                <p>(Traslado en Colectivos)</p>
            </div>
        </div>

        <div className='gridPrograma' >
            <HoraCharla hora={'22:30'}/>
            <div>
                <DetalleCharla tema={'Cena'} />
            </div>
        </div>

        <div className='mb-4 mt-6'>
            <div className="mt-4 poppins-bold gridPrograma">
                <img src={clock} style={{maxWidth: '1.8rem', margin:'auto'}}/>
                <p className='color is-size-4-desktop is-size-6-mobile'>Miércoles 7 de diciembre</p>
             </div>
        </div>

        <div className='gridPrograma' >
            <HoraCharla hora={'10:00'}/>
            <div>
                <DetalleCharla tema={'Panel Capacitación y formación de cuadros. ¿De qué manera construimos agendas juveniles con formación y capacitación permanente?. ¿Qué está faltando en la formación integral de cuadros?.'} />
                <Disertante disertante={'Daniel Villa'} cargo={'Secretario de Capacitación de FATUN'}/>
                <Disertante disertante={'Guillermo Ortolano'} cargo={'Coordinador de Apoyo a la Formación Sindical Ministerio de Trabajo, Empleo y Seguridad Social de la Nación'}/>
                <p>Modera:</p>
                    <Disertante disertante={'Mercedes Maffeo'} cargo={'Colaboradora Escuela Superior Peronista de JSP / ATUNH'}/>
            </div>
        </div>

        <div className='gridPrograma' >
            <HoraCharla hora={'11:30'}/>
            <div>
                <DetalleCharla tema={'Panel Cambio Climático y Desarollo Sostenible. ¿Cómo construimos agendas juveniles que incorporen compromiso con el cuidado del planeta?. ¿Qué desafíos tenemos por delante?'} />
                <Disertante disertante={'Sol Klas'} cargo={'Secretaria de Ambiente de APOC - Frente Sindical de Acción Climática'}/>
                <Disertante disertante={'Mónica Casanovas'} cargo={'Subcoordinadora de la Licenciatura en Gestión Ambiental y Vicedirectora del Instituto Cs. Sociales y Administración de la UNAJ'}/>
                <Disertante disertante={'Juan Martín Sánchez'} cargo={'Asesor de la Secretaría de Política Ambiental en Recursos Naturales del Ministerio de Ambiente y Desarrollo Sustentable'}/>
                <p>Modera:</p>
                    <Disertante disertante={'Rodrigo Picchi'} cargo={'Congresal de ATUNAJ'}/>
                </div>
        </div>

        <div className='gridPrograma' >
            <HoraCharla hora={'13:00'}/>
            <div>
                <DetalleCharla tema={'Almuerzo'} />
            </div>
        </div>

        <div className='gridPrograma' >
            <HoraCharla hora={'15:00'}/>
            <div>
                <DetalleCharla tema={'Panel Políticas de Salud, Hospitales Universitarios y OSFATUN'} />
                <Disertante disertante={'Mauricio D’Alessandro'} cargo={'Secretario de Organización de FATUN'}/>
                <Disertante disertante={'Alberto Natan'} cargo={'Gerente de Prestaciones Médicas de OSFATUN'}/>
                <Disertante disertante={'Matias Lachimia'} cargo={'Departamento de Delegaciones de OSFATUN'}/>
                <Disertante disertante={'Leonor Ojeda'} cargo={'Hospital de Clínicas Virgen de Fátima - UNLar'}/>
                <Disertante disertante={'Marta Alegre'} cargo={'Dirección de Salud de la Secretaría de Bienestar Universitario de la UNC'}/>
                <p>Modera:</p>
                    <Disertante disertante={'Marcos Prol'} cargo={'Delegado General Instituto Lanari y Secretario de Acción Social APUBA'}/>
             </div>
        </div>

        <div className='gridPrograma' >
            <HoraCharla hora={'17:00'}/>
            <div>
                <DetalleCharla tema={'Refrigerio'} />
            </div>
        </div>

        <div className='gridPrograma' >
            <HoraCharla hora={'18:00'}/>
            <div>
                <DetalleCharla tema={'Panel Político Universitario. Conversatorio: ¿Cómo Construimos Comunidad Universitaria Organizada?'} />
                <Disertante disertante={'Leandro “Chaucha” Quiroga'} cargo={'Subsecretario de Fortalecimiento de Trayectorias Estudiantiles del Ministerio de Educación de Nación'}/>
                <Disertante disertante={'Carlos Greco'} cargo={'Vicepresidente del CIN y Rector de la UNSAM'}/>
                <Disertante disertante={'Daniel Ricci'} cargo={'Secretario General de FEDUN'}/>
                <Disertante disertante={'Yamile Socolovsky'} cargo={'Secretaria de Relaciones Internacionales de CONADU'}/>
                <Disertante disertante={'Franco Suárez'} cargo={'MPE'}/>
                <Disertante disertante={'José Pepe Arlegui'} cargo={'FUNE'}/>
                <Disertante disertante={'Micaela Ibañez'} cargo={'Presidenta del Centro de Filosofía y Letras de la Universidad de Buenos Aires / La Cámpora'}/>
                <Disertante disertante={'Ricardo Cuellar'} cargo={'JUP'}/>
                <Disertante disertante={'Florencia Seminara'} cargo={'Secretaria General de la FUBA'}/>
                <Disertante disertante={'Nehuen Corbeletto'} cargo={'CEPA'}/>
                <Disertante disertante={'Lucía Morini'} cargo={'MUE'}/>
                <p>Modera:</p>
                    <Disertante disertante={'Ignacio “Nacho” Bruno'} cargo={'Secretario Gremial de ATULP'}/>
             </div>
        </div>

        <div className='gridPrograma' >
            <HoraCharla hora={'19:30'}/>
            <div>
                <DetalleCharla tema={'Panel de cierre y aportes a la Juventud NODOCENTE de FATUN'} />
                <Disertante disertante={'Walter Merkis'} cargo={'Secretario General de FATUN'}/>
                <Disertante disertante={'Facundo Romero'} cargo={'Secretario de Juventud de FATUN'}/>
                <Disertante cargo={'Miembros de la Mesa Ejecutiva'}/>
            </div>
        </div>

        <div className='gridPrograma' >
            <HoraCharla hora={'21:00'}/>
            <div>
                <DetalleCharla tema={'Cena despedida'} />
            </div>
        </div>
    </div>
    )
}

export default ItinerarioContainer

